// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cyklar-fatbikes-mdx": () => import("./../../../src/pages/cyklar/fatbikes.mdx" /* webpackChunkName: "component---src-pages-cyklar-fatbikes-mdx" */),
  "component---src-pages-cyklar-hopfallbara-mdx": () => import("./../../../src/pages/cyklar/hopfallbara.mdx" /* webpackChunkName: "component---src-pages-cyklar-hopfallbara-mdx" */),
  "component---src-pages-cyklar-hybridcyklar-mdx": () => import("./../../../src/pages/cyklar/hybridcyklar.mdx" /* webpackChunkName: "component---src-pages-cyklar-hybridcyklar-mdx" */),
  "component---src-pages-cyklar-index-mdx": () => import("./../../../src/pages/cyklar/index.mdx" /* webpackChunkName: "component---src-pages-cyklar-index-mdx" */),
  "component---src-pages-cyklar-klassiska-mdx": () => import("./../../../src/pages/cyklar/klassiska.mdx" /* webpackChunkName: "component---src-pages-cyklar-klassiska-mdx" */),
  "component---src-pages-cyklar-ladcyklar-mdx": () => import("./../../../src/pages/cyklar/ladcyklar.mdx" /* webpackChunkName: "component---src-pages-cyklar-ladcyklar-mdx" */),
  "component---src-pages-cyklar-lastcyklar-mdx": () => import("./../../../src/pages/cyklar/lastcyklar.mdx" /* webpackChunkName: "component---src-pages-cyklar-lastcyklar-mdx" */),
  "component---src-pages-cyklar-mittmotor-mdx": () => import("./../../../src/pages/cyklar/mittmotor.mdx" /* webpackChunkName: "component---src-pages-cyklar-mittmotor-mdx" */),
  "component---src-pages-cyklar-mountainbikes-mdx": () => import("./../../../src/pages/cyklar/mountainbikes.mdx" /* webpackChunkName: "component---src-pages-cyklar-mountainbikes-mdx" */),
  "component---src-pages-cyklar-racers-mdx": () => import("./../../../src/pages/cyklar/racers.mdx" /* webpackChunkName: "component---src-pages-cyklar-racers-mdx" */),
  "component---src-pages-cyklar-singlespeeds-mdx": () => import("./../../../src/pages/cyklar/singlespeeds.mdx" /* webpackChunkName: "component---src-pages-cyklar-singlespeeds-mdx" */),
  "component---src-pages-guider-elcykelbatterier-mdx": () => import("./../../../src/pages/guider/elcykelbatterier.mdx" /* webpackChunkName: "component---src-pages-guider-elcykelbatterier-mdx" */),
  "component---src-pages-guider-index-mdx": () => import("./../../../src/pages/guider/index.mdx" /* webpackChunkName: "component---src-pages-guider-index-mdx" */),
  "component---src-pages-guider-motorplacering-mdx": () => import("./../../../src/pages/guider/motorplacering.mdx" /* webpackChunkName: "component---src-pages-guider-motorplacering-mdx" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-marken-batavus-mdx": () => import("./../../../src/pages/marken/batavus.mdx" /* webpackChunkName: "component---src-pages-marken-batavus-mdx" */),
  "component---src-pages-marken-bergamont-mdx": () => import("./../../../src/pages/marken/bergamont.mdx" /* webpackChunkName: "component---src-pages-marken-bergamont-mdx" */),
  "component---src-pages-marken-bh-mdx": () => import("./../../../src/pages/marken/bh.mdx" /* webpackChunkName: "component---src-pages-marken-bh-mdx" */),
  "component---src-pages-marken-cannondale-mdx": () => import("./../../../src/pages/marken/cannondale.mdx" /* webpackChunkName: "component---src-pages-marken-cannondale-mdx" */),
  "component---src-pages-marken-centurion-mdx": () => import("./../../../src/pages/marken/centurion.mdx" /* webpackChunkName: "component---src-pages-marken-centurion-mdx" */),
  "component---src-pages-marken-crescent-mdx": () => import("./../../../src/pages/marken/crescent.mdx" /* webpackChunkName: "component---src-pages-marken-crescent-mdx" */),
  "component---src-pages-marken-ecoride-mdx": () => import("./../../../src/pages/marken/ecoride.mdx" /* webpackChunkName: "component---src-pages-marken-ecoride-mdx" */),
  "component---src-pages-marken-evobike-mdx": () => import("./../../../src/pages/marken/evobike.mdx" /* webpackChunkName: "component---src-pages-marken-evobike-mdx" */),
  "component---src-pages-marken-fitnord-mdx": () => import("./../../../src/pages/marken/fitnord.mdx" /* webpackChunkName: "component---src-pages-marken-fitnord-mdx" */),
  "component---src-pages-marken-flyer-mdx": () => import("./../../../src/pages/marken/flyer.mdx" /* webpackChunkName: "component---src-pages-marken-flyer-mdx" */),
  "component---src-pages-marken-frappe-mdx": () => import("./../../../src/pages/marken/frappe.mdx" /* webpackChunkName: "component---src-pages-marken-frappe-mdx" */),
  "component---src-pages-marken-giant-mdx": () => import("./../../../src/pages/marken/giant.mdx" /* webpackChunkName: "component---src-pages-marken-giant-mdx" */),
  "component---src-pages-marken-haibike-mdx": () => import("./../../../src/pages/marken/haibike.mdx" /* webpackChunkName: "component---src-pages-marken-haibike-mdx" */),
  "component---src-pages-marken-husqvarna-mdx": () => import("./../../../src/pages/marken/husqvarna.mdx" /* webpackChunkName: "component---src-pages-marken-husqvarna-mdx" */),
  "component---src-pages-marken-index-mdx": () => import("./../../../src/pages/marken/index.mdx" /* webpackChunkName: "component---src-pages-marken-index-mdx" */),
  "component---src-pages-marken-kronan-mdx": () => import("./../../../src/pages/marken/kronan.mdx" /* webpackChunkName: "component---src-pages-marken-kronan-mdx" */),
  "component---src-pages-marken-lifebike-mdx": () => import("./../../../src/pages/marken/lifebike.mdx" /* webpackChunkName: "component---src-pages-marken-lifebike-mdx" */),
  "component---src-pages-marken-merida-mdx": () => import("./../../../src/pages/marken/merida.mdx" /* webpackChunkName: "component---src-pages-marken-merida-mdx" */),
  "component---src-pages-marken-momas-mdx": () => import("./../../../src/pages/marken/momas.mdx" /* webpackChunkName: "component---src-pages-marken-momas-mdx" */),
  "component---src-pages-marken-monark-mdx": () => import("./../../../src/pages/marken/monark.mdx" /* webpackChunkName: "component---src-pages-marken-monark-mdx" */),
  "component---src-pages-marken-nitrox-mdx": () => import("./../../../src/pages/marken/nitrox.mdx" /* webpackChunkName: "component---src-pages-marken-nitrox-mdx" */),
  "component---src-pages-marken-rawbike-mdx": () => import("./../../../src/pages/marken/rawbike.mdx" /* webpackChunkName: "component---src-pages-marken-rawbike-mdx" */),
  "component---src-pages-marken-rock-machine-mdx": () => import("./../../../src/pages/marken/rock-machine.mdx" /* webpackChunkName: "component---src-pages-marken-rock-machine-mdx" */),
  "component---src-pages-marken-scott-mdx": () => import("./../../../src/pages/marken/scott.mdx" /* webpackChunkName: "component---src-pages-marken-scott-mdx" */),
  "component---src-pages-marken-sensa-mdx": () => import("./../../../src/pages/marken/sensa.mdx" /* webpackChunkName: "component---src-pages-marken-sensa-mdx" */),
  "component---src-pages-marken-skeppshult-mdx": () => import("./../../../src/pages/marken/skeppshult.mdx" /* webpackChunkName: "component---src-pages-marken-skeppshult-mdx" */),
  "component---src-pages-marken-stalhasten-mdx": () => import("./../../../src/pages/marken/stalhasten.mdx" /* webpackChunkName: "component---src-pages-marken-stalhasten-mdx" */),
  "component---src-pages-marken-superior-mdx": () => import("./../../../src/pages/marken/superior.mdx" /* webpackChunkName: "component---src-pages-marken-superior-mdx" */),
  "component---src-pages-marken-triobike-mdx": () => import("./../../../src/pages/marken/triobike.mdx" /* webpackChunkName: "component---src-pages-marken-triobike-mdx" */),
  "component---src-pages-marken-vidaxl-mdx": () => import("./../../../src/pages/marken/vidaxl.mdx" /* webpackChunkName: "component---src-pages-marken-vidaxl-mdx" */),
  "component---src-pages-marken-winora-mdx": () => import("./../../../src/pages/marken/winora.mdx" /* webpackChunkName: "component---src-pages-marken-winora-mdx" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */)
}

